import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PricingDetail, InstantEpriceContainer } from 'wsm-pricing-display';
import { useDealerOptions } from 'wsm-pricing-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import { ButtonList } from 'wsm-vehicle-cta-display';
import { Tabs, Tab, Collapse } from 'react-bootstrap';
import { useRequestData, usePrefs, useLabels, useFlags } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { DetailedIncentives, filterIncentiveIds } from 'wsm-incentive-display';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import logger from 'ws-scripts/modules/logger';
import HighlightedAttributeContainer from './HighlightedAttributeContainer';
import VehicleAttributeList from './VehicleAttributeList';
import Callout from '../Callout';
import VehiclePackages from './VehiclePackages';
import { getVehicleObj } from '../../../utilities/vehicleTracking';
import { pricingMap } from '../../../utilities/pricingMap';
import {
	getTargetLocations,
	getVehicleLocationName
} from '../../../utilities/getTargetLocations';
import {
	selectIsGridLayout,
	selectIsTabbedLayout
} from '../../../features/layoutSlice';
import ConditionalWrapper from '../../ConditionalWrapper';
import LocationDistance from './LocationDistance';
import { checkConcaternateAttr } from '../../../utilities/concatenateAttributeCheck';
import WindowSticker from './WindowSticker';
import { validateWindowStickerCondition } from '../../../utilities/validateWindowStickerCondition';

const VehicleCardData = ({
	accountId,
	accountInfo,
	incentives,
	vehicle,
	highlightedAttributes,
	isDisplayingEPrice,
	setIsDisplayingEPrice,
	showExpandedPricing,
	setShowExpandedPricing
}) => {
	const {
		buttonSizeEPrice,
		buttonStyleEPrice,
		ePriceFontWeight,
		incentiveDisplayGrouping,
		incentiveTitleCustom,
		incentiveTitleDisplay,
		instantEpricePreventMultipleEpriceLeads,
		instantEpriceShowAllAfterLeadSubmit,
		restrictInsertLocationsForVehicles,
		showDealerConditionalIncentives,
		showDealerRegularIncentives,
		showIncentives,
		showInstantEpriceForSharedInventory,
		showOemConditionalIncentives,
		showOemNationalIncentives,
		showPackages,
		tabInfoLabel,
		tabOrder,
		tabPricingLabel,
		tabSpecialsLabel,
		hideInformationTabs,
		showInformationTabs,
		showSRPWindowSticker
	} = usePrefs();

	const {
		accountId: vehicleAccountId,
		attributes = [],
		bestIncentiveIds = [],
		callout: callouts,
		condition,
		incentiveIds = [],
		inventoryButtons = [],
		link,
		packages = [],
		pricing = {},
		uuid,
		images,
		title
	} = vehicle;

	const { franchiseList } = useSelector((state) => state.franchiseList);
	const { dealerCodes } = useSelector((state) => state.dealerCodes);

	const showWindowStickerCondition = useMemo(() => {
		return validateWindowStickerCondition(
			vehicle,
			showSRPWindowSticker,
			franchiseList,
			dealerCodes
		);
	}, [vehicle, showSRPWindowSticker, franchiseList, dealerCodes]);

	const locationDistanceObj = attributes.find(
		(item) => item.name === 'locationDistance'
	);

	let locationDistanceLabel = locationDistanceObj?.labeledValue;

	if (!checkConcaternateAttr('locationDistance', locationDistanceLabel)) {
		locationDistanceLabel = locationDistanceObj?.value;
	}

	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	// TODO: remove mapping here (and in ws-detailed-pricing) and update wsm-pricing-display to use lowercase version once GVM databus is retired
	const componentPricing = pricingMap(pricing);

	const incentiveIdList = incentiveIds || bestIncentiveIds || [];

	const [shouldRequestDealerOptions, setShouldRequestDealerOptions] =
		useState(false);
	const { userProfile } = useSelector((state) => state);
	const { pageAlias } = useSelector((state) => state.widgetInfo);
	const { useSimplifiedListing } = useSelector((state) => state.layout);

	const { widgetName, windowId, locale, deviceType } = useRequestData();
	const labels = useLabels();
	const flags = useFlags();
	const useMutipleIncentivesLink = flags['use-multiple-incentive-link'];
	const dealerOptions = useDealerOptions(
		uuid,
		locale,
		shouldRequestDealerOptions,
		widgetName
	);

	setNewRelicCustomAttribute('srpHiddenTabs', hideInformationTabs);

	const handleDealerOptionsMouseEnter = () => {
		setShouldRequestDealerOptions(true);
	};

	const showEPrice = (id) => {
		setIsDisplayingEPrice(id === uuid && id !== 'none');
	};

	const customLabel = flags['srp-cta-label-change'];
	const controlCTAs = flags['srp-control-cta-buttons'];

	const filteredIncentives = filterIncentiveIds(incentives, incentiveIdList);
	const vehicleTrackingData = getVehicleObj(uuid);
	const modifyVehicleData = {
		images,
		title
	};

	const targetLocations = getTargetLocations(
		vehicle,
		restrictInsertLocationsForVehicles
	);
	const vehiclePaymentsLocation = getVehicleLocationName(
		'vehicle-payments',
		targetLocations,
		restrictInsertLocationsForVehicles
	);
	const vehiclePricingLocation = getVehicleLocationName(
		'vehicle-pricing',
		targetLocations,
		restrictInsertLocationsForVehicles
	);
	const vehicleCtasLocation = getVehicleLocationName(
		'vehicle-ctas',
		targetLocations,
		restrictInsertLocationsForVehicles
	);

	const ePriceButton = !isDisplayingEPrice ? (
		<InstantEpriceContainer
			accountId={accountId}
			buttonSize={buttonSizeEPrice}
			buttonStyle={buttonStyleEPrice}
			ePriceFontWeight={ePriceFontWeight}
			instantEpricePreventMultipleEpriceLeads={
				instantEpricePreventMultipleEpriceLeads
			}
			instantEpriceShowAllAfterLeadSubmit={
				instantEpriceShowAllAfterLeadSubmit
			}
			key="eprice"
			pageAlias={pageAlias}
			pricing={componentPricing}
			showEPrice={(id) => showEPrice(id)}
			showInstantEpriceForSharedInventory={
				showInstantEpriceForSharedInventory
			}
			userProfile={userProfile}
			vehicleId={uuid}
			vehicleAccountId={vehicleAccountId}
		/>
	) : null;

	const pricingElement = (
		<ConditionalWrapper
			condition={
				useSimplifiedListing &&
				!showInformationTabs?.toLowerCase().includes('pricing')
			}
			wrapper={(children) => (
				<>
					<Collapse in={showExpandedPricing}>
						<div
							className={[
								deviceType === 'MOBILE'
									? 'ddc-font-size-small'
									: 'ddc-font-size-xsmall',
								'mt-2'
							]}
						>
							{children}
							<button
								type="button"
								className="btn-link btn-no-decoration p-0 d-block text-muted flex-fill w-100 text-right mb-4"
								onClick={() => setShowExpandedPricing(false)}
							>
								{labels.get('HIDE_PRICING')}
							</button>
						</div>
					</Collapse>
				</>
			)}
		>
			<ErrorBoundary
				errorHandler={(error, errorInfo) => {
					setNewRelicCustomAttribute(
						'SRP ERROR',
						`ws-inv-listing error boundary: wsm-pricing-display error.\n${error}`
					);
					const newError = new Error(
						`ws-inv-listing error boundary.\n${error}`
					);
					newError.originalError = error;
					newError.originalStackTrace = errorInfo.componentStack;
					logger.error(`${newError}\n${newError.originalStackTrace}`);
				}}
			>
				<PricingDetail
					{...{
						pricing: componentPricing,
						incentives,
						accountInfo,
						accountId,
						condition,
						isGridLayout,
						userProfile
					}}
					fetchDealerOptionsCallback={handleDealerOptionsMouseEnter}
					dealerOptions={dealerOptions}
					vehicleAccountId={vehicleAccountId}
					vehicleId={uuid}
					isDisplayingEPrice={isDisplayingEPrice}
					useMutipleIncentivesLink={useMutipleIncentivesLink}
					vehicleData={modifyVehicleData}
				/>
				<div
					className="hidden vehicle-card-payments mt-3"
					data-location={vehiclePaymentsLocation}
				/>
			</ErrorBoundary>
		</ConditionalWrapper>
	);

	// Tabs
	const tabArray = [];
	const orderedTabsData = useSimplifiedListing
		? showInformationTabs
		: tabOrder;
	const orderedTabs = orderedTabsData
		.replace(/\s/g, '')
		.toLowerCase()
		.split(',');
	const tabLabels = {
		info: labels.get(tabInfoLabel),
		specials: labels.get(tabSpecialsLabel),
		pricing: labels.get(tabPricingLabel)
	};
	const tabComponents = {
		info: (
			<React.Fragment>
				<VehicleAttributeList attributes={attributes} />
				{isTabbedLayout && !useSimplifiedListing && (
					<React.Fragment>
						{showPackages === 'true' && (
							<VehiclePackages packages={packages} link={link} />
						)}
						<Callout callouts={callouts} vehicle={vehicle} />
					</React.Fragment>
				)}
			</React.Fragment>
		),
		specials:
			showIncentives === 'true' && filteredIncentives.length ? (
				<DetailedIncentives
					incentives={filteredIncentives}
					accountInfo={accountInfo}
					incentiveTitleDisplay={incentiveTitleDisplay}
					incentiveTitleCustom={incentiveTitleCustom}
					incentiveDisplayGrouping={incentiveDisplayGrouping}
					enableAccordion={!isTabbedLayout}
					showDealerRegularIncentives={
						showDealerRegularIncentives === 'true'
					}
					showDealerConditionalIncentives={
						showDealerConditionalIncentives === 'true'
					}
					showOemNationalIncentives={
						showOemNationalIncentives === 'true'
					}
					showOemConditionalIncentives={
						showOemConditionalIncentives === 'true'
					}
					useMutipleIncentivesLink={useMutipleIncentivesLink}
					vehicleData={{
						...modifyVehicleData,
						pricing,
						condition
					}}
				/>
			) : null,
		pricing: pricingElement
	};

	orderedTabs.forEach((key) => {
		// eslint-disable-next-line no-unused-expressions
		tabComponents[key] &&
			(useSimplifiedListing
				? showInformationTabs?.toLowerCase().includes(key)
				: !hideInformationTabs?.toLowerCase().includes(key)) &&
			tabArray.push({
				eventKey: key,
				key,
				title: tabLabels[key],
				component: tabComponents[key]
			});
	});
	const useSRPWindowStickerPopover = flags['srp-window-sticker-link-change'];

	const hasInventoryButtons = inventoryButtons && inventoryButtons.length > 0;
	// prettier is causing the linter to error. I really do not care about these indents
	/* eslint-disable react/jsx-indent */
	const ctaButtons = targetLocations.has('vehicle-ctas')
		? [
				<ButtonList
					buttons={[]}
					key="inventoryButtons"
					vehicleTrackingData={undefined}
					dataLocation={vehicleCtasLocation}
				/>
		  ]
		: [
				ePriceButton,
				<ButtonList
					buttons={inventoryButtons}
					key="inventoryButtons"
					isSRP
					useSRPWindowStickerPopover={useSRPWindowStickerPopover}
					vehicleTrackingData={
						hasInventoryButtons ? vehicleTrackingData : undefined
					}
					dataLocation={vehicleCtasLocation}
				/>
		  ];
	/* eslint-enable react/jsx-indent */
	const defaultCTAButton = [
		{
			btnAttributes: {
				'data-location': 'vehicle-request-a-quote=button',
				'data-width': '375'
			},
			btnClasses: 'dialog btn-block btn-sm btn-primary btn',
			btnLabel: customLabel ?? 'Request More Info',
			btnHref: `/lead-form.htm?itemId=${uuid}&category=AUTO&parentPageAlias=${pageAlias}`
		}
	];
	const defaultCTA = (
		<ButtonList
			buttons={defaultCTAButton}
			key="inventoryButtons"
			vehicleTrackingData={undefined}
			dataLocation={vehicleCtasLocation}
		/>
	);
	let showCTAs;
	if (controlCTAs === 'none') {
		showCTAs = ctaButtons;
	} else if (controlCTAs === 'hide') {
		showCTAs = null;
	} else if (controlCTAs === 'default') {
		showCTAs = defaultCTA;
	}

	// return for simplified listing
	if (useSimplifiedListing) {
		return (
			<React.Fragment>
				{!showInformationTabs?.toLowerCase().includes('pricing') &&
					pricingElement}
				<HighlightedAttributeContainer
					highlightedAttributes={highlightedAttributes}
					accountInfo={accountInfo}
				/>
				{showInformationTabs && (
					<>
						{tabArray?.length > 1 && (
							<Tabs
								className="vehicle-card-tabs tabs tabs-fill"
								id={uuid}
								onSelect={(key) =>
									trackEvent(widgetName, windowId, {
										element: `${tabLabels[key]} tab`,
										result: 'Tab displayed'
									})
								}
							>
								{tabArray.map((tab) => (
									<Tab
										eventKey={tab.eventKey}
										key={tab.key}
										title={tab.title}
									>
										{tab.component}
									</Tab>
								))}
							</Tabs>
						)}
						{tabArray?.length === 1 && tabArray[0]?.component}
					</>
				)}

				<Callout callouts={callouts} vehicle={vehicle} />
				<div className="locationCTAContainer vehicle-ctas">
					{locationDistanceLabel && (
						<div className="mb-3">
							<LocationDistance
								label={locationDistanceLabel}
								accountId={accountId}
							/>
						</div>
					)}
					{showCTAs && (
						<ErrorBoundary
							errorHandler={(error, errorInfo) => {
								setNewRelicCustomAttribute(
									'SRP ERROR',
									`ws-inv-listing error boundary: cta buttons error.\n${error}`
								);
								const newError = new Error(
									`ws-inv-listing error boundary.\n${error}`
								);
								newError.originalError = error;
								newError.originalStackTrace =
									errorInfo.componentStack;
								logger.error(
									`${newError}\n${newError.originalStackTrace}`
								);
							}}
						>
							{showCTAs}
						</ErrorBoundary>
					)}
					{showWindowStickerCondition && (
						<WindowSticker vehicle={vehicle} />
					)}
				</div>
				<div
					className="hidden vehicle-card-pricing mt-3"
					data-location={vehiclePricingLocation}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{isTabbedLayout ? (
				<React.Fragment>
					<Tabs
						className="vehicle-card-tabs tabs tabs-fill"
						id={uuid}
						onSelect={(key) =>
							trackEvent(widgetName, windowId, {
								element: `${tabLabels[key]} tab`,
								result: 'Tab displayed'
							})
						}
					>
						{tabArray?.length > 1
							? tabArray.map((tab) => (
									/* eslint-disable react/jsx-indent */
									<Tab
										eventKey={tab.eventKey}
										key={tab.key}
										title={tab.title}
									>
										{tab.component}
									</Tab>
							  ))
							: tabArray[0]?.component}
					</Tabs>
					{/* to make sure VehicleTitle always displays pricing */}
					{hideInformationTabs?.toLowerCase().includes('pricing') && (
						<div className="d-none">{tabComponents.pricing}</div>
					)}
					<div className="locationCTAContainer vehicle-ctas">
						{locationDistanceLabel && (
							<div className="mb-3">
								<LocationDistance
									label={locationDistanceLabel}
									accountId={accountId}
								/>
							</div>
						)}
						{showCTAs && (
							<ErrorBoundary
								errorHandler={(error, errorInfo) => {
									setNewRelicCustomAttribute(
										'SRP ERROR',
										`ws-inv-listing error boundary: cta buttons error.\n${error}`
									);
									const newError = new Error(
										`ws-inv-listing error boundary.\n${error}`
									);
									newError.originalError = error;
									newError.originalStackTrace =
										errorInfo.componentStack;
									logger.error(
										`${newError}\n${newError.originalStackTrace}`
									);
								}}
							>
								{showCTAs}
							</ErrorBoundary>
						)}
						{showWindowStickerCondition && (
							<WindowSticker vehicle={vehicle} />
						)}
					</div>
					<div
						className="hidden vehicle-card-pricing mt-3"
						data-location={vehiclePricingLocation}
					/>
				</React.Fragment>
			) : (
				<>
					<div className="d-md-flex">
						<div className="vehicle-card-info-packages w-100">
							{tabComponents.info}
							{showPackages === 'true' && (
								<VehiclePackages
									packages={packages}
									link={link}
								/>
							)}
						</div>
						<div className="vehicle-card-pricing w-100">
							<div className="w-100">{tabComponents.pricing}</div>
							<div className="w-100">
								{tabComponents.specials}
							</div>
							{locationDistanceLabel && useSimplifiedListing && (
								<div className="mb-3">
									<LocationDistance
										label={locationDistanceLabel}
										accountId={accountId}
									/>
								</div>
							)}
							<div className="w-100">
								<ErrorBoundary
									errorHandler={(error, errorInfo) => {
										setNewRelicCustomAttribute(
											'SRP ERROR',
											`ws-inv-listing error boundary: cta buttons error.\n${error}`
										);
										const newError = new Error(
											`ws-inv-listing error boundary.\n${error}`
										);
										newError.originalError = error;
										newError.originalStackTrace =
											errorInfo.componentStack;
										logger.error(
											`${newError}\n${newError.originalStackTrace}`
										);
									}}
								>
									{ctaButtons}
									{showWindowStickerCondition && (
										<WindowSticker vehicle={vehicle} />
									)}
								</ErrorBoundary>
							</div>
							<div className="w-100">
								<div
									className="hidden vehicle-card-pricing mt-3"
									data-location={vehiclePricingLocation}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</React.Fragment>
	);
};

VehicleCardData.propTypes = {
	accountId: PropTypes.string.isRequired,
	accountInfo: PropTypes.shape({}),
	incentives: PropTypes.shape({}),
	isDisplayingEPrice: PropTypes.bool.isRequired,
	setIsDisplayingEPrice: PropTypes.func.isRequired,
	highlightedAttributes: PropTypes.PropTypes.arrayOf(PropTypes.shape({})),
	vehicle: PropTypes.shape({
		accountId: PropTypes.string,
		attributes: PropTypes.arrayOf(PropTypes.shape({})),
		bestIncentiveIds: PropTypes.arrayOf(PropTypes.string),
		callout: PropTypes.arrayOf(PropTypes.shape({})),
		condition: PropTypes.string,
		incentiveIds: PropTypes.arrayOf(PropTypes.string),
		inventoryButtons: PropTypes.arrayOf(PropTypes.shape({})),
		link: PropTypes.string,
		packages: PropTypes.arrayOf(PropTypes.string),
		pricing: PropTypes.shape({
			dprice: PropTypes.arrayOf(PropTypes.shape({})),
			dPrice: PropTypes.arrayOf(PropTypes.shape({})),
			eprice: PropTypes.arrayOf(PropTypes.shape({})),
			ePrice: PropTypes.arrayOf(PropTypes.shape({}))
		}),
		title: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string)
		]),
		uuid: PropTypes.string,
		images: PropTypes.arrayOf(PropTypes.string)
	}).isRequired,
	showExpandedPricing: PropTypes.bool.isRequired,
	setShowExpandedPricing: PropTypes.func.isRequired
};

VehicleCardData.defaultProps = {
	incentives: {}
};

export default VehicleCardData;
